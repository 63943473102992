import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Definitions
var clientId = "A59E7351E6CE2CE67D86A9A094F3B77E60BFCD1F";
var clientSecret = "24FE73D732346DEF92317B0B93AFDD0059DA9C1B";
// var accessToken = "F7D446306A7AD9FBC297198DB6B94101E7C7D158"; 
// const pg = require('pg');
var users = 'joaoggoncalo'

// // PostgreSQL connection configuration
// const pool = new pg.Pool({
//     connectionString: 'postgres://untappd:CRssdSL2hpWgPCsPpa23Om0NkwC1Lxti@dpg-cn304hol5elc73cjngqg-a.frankfurt-postgres.render.com/untappd'
// });


function Load()  {
    const [checkins, setCheckins] = useState([]);

    useEffect(() => {
        const fetchCheckins = async () => {
            let allCheckins = [];
            let nextUrl = 'https://api.untappd.com/v4/user/checkins/' + users;

            try {
                while (nextUrl) {
                    const response = await axios.get(nextUrl, {
                        params: {
                            client_id: clientId,
                            client_secret: clientSecret,
                            // access_token: accessToken,
                            limit: 20
                        }
                    });
                    console.log(response.data.response)
                    allCheckins = [...allCheckins, ...response.data.response.checkins.items];

                    nextUrl = response.data.response.pagination.next_url;
                }

                setCheckins(allCheckins);
            } catch (error) {
                console.error('Error fetching checkins:', error);
            }
        };

        fetchCheckins();
    }, []);

    return (
        <div>
            <h1>Recent Checkins</h1>
            <ol>
                {checkins.map(checkin => (
                    <li key={checkin.checkin_id}>
                        {checkin.user.user_name} drank {checkin.beer.beer_name} by {checkin.brewery.brewery_name} {checkin.venue && `at ${checkin.venue.venue_name}`} on {checkin.created_at}
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default Load;